import React, { useState, useEffect, ChangeEventHandler } from 'react'

import PropTypes from 'prop-types'

import {
	TextField,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Grid,
	Avatar,
	Badge,
	useTheme,
	useMediaQuery,
	Checkbox,
} from '@material-ui/core'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import Autocomplete from '@material-ui/lab/Autocomplete'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useSelector, useDispatch } from 'react-redux'
import { Controller } from 'react-hook-form'
import PhoneNumberFormat from '../../../../../components/PhoneNumberFormat'
import { getFullChildCompanies } from '../../../../../redux/requests/Accounts.request'
import { getListCompaniesByUser } from '../../../../../redux/actions/CompanyByUser.actions'

import useStyles from './styles'
import type { CompanyInfo, NestableCompanyInfo } from '../../../../../redux/requests/Accounts.types'
import { filterCompaniesBySearchText } from './filterCompaniesBySearchText'

const nestedCompanyPropType: PropTypes.InferProps<NestableCompanyInfo> = {
	id: PropTypes.number,
	account_number: PropTypes.string,
	name: PropTypes.string,
	parent_id: PropTypes.string,
	contact: PropTypes.string,
	email: PropTypes.string,
	phone: PropTypes.string,
	level: PropTypes.number,
}
nestedCompanyPropType.children = PropTypes.arrayOf(PropTypes.shape(nestedCompanyPropType))

const propTypes = {
	errors: PropTypes.objectOf(PropTypes.any).isRequired,
	register: PropTypes.func.isRequired,
	control: PropTypes.objectOf(PropTypes.any).isRequired,
	userPhoto: PropTypes.arrayOf(PropTypes.any).isRequired,
	setUserPhoto: PropTypes.func.isRequired,
	phoneNumber: PropTypes.string.isRequired,
	setPhoneNumber: PropTypes.func.isRequired,
	childrenCompanies: PropTypes.arrayOf(PropTypes.shape(nestedCompanyPropType).isRequired).isRequired,
	setChildrenCompanies: PropTypes.func.isRequired,
	isEdit: PropTypes.bool.isRequired,
	editUserChildrenCompanies: PropTypes.arrayOf(PropTypes.any).isRequired,
	isFromEditAccount: PropTypes.bool,
	setValue: PropTypes.func.isRequired,
	isEditProfile: PropTypes.bool,
	errorPhoneNumber: PropTypes.string.isRequired,
	accountData: PropTypes.shape({
		id: PropTypes.any,
		parentCompany: PropTypes.shape({
			id: PropTypes.any,
		}),
	}),
	setCompanyAccount: PropTypes.func.isRequired,
}

type ElementState = {
	companies: { parents: any }
	accounts: {
		parentCompanyInUpdate: { id: string }
		childrenCompaniesInUpdate: any
	}
	companiesByUser: {
		companies: Array<NestableCompanyInfo | CompanyInfo>
	}
}

const BasicInformation: React.FC<PropTypes.InferProps<typeof propTypes>> = (props) => {
	const {
		register,
		errors,
		control,
		userPhoto,
		setUserPhoto,
		phoneNumber,
		setPhoneNumber,
		childrenCompanies,
		setChildrenCompanies,
		isEdit,
		editUserChildrenCompanies,
		isFromEditAccount,
		setValue,
		isEditProfile,
		errorPhoneNumber,
		accountData,
		setCompanyAccount,
	} = props
	const dispatch = useDispatch()
	const classes = useStyles()
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.down('md'))
	const parentAccount = useSelector<ElementState, ElementState['companies']['parents']>(
		(state) => state.companies.parents,
	)
	const parentCompanyInUpdate = useSelector<ElementState, ElementState['accounts']['parentCompanyInUpdate']>(
		(state) => state.accounts.parentCompanyInUpdate,
	)
	const childrenCompaniesInUpdate = useSelector<ElementState, ElementState['accounts']['childrenCompaniesInUpdate']>(
		(state) => state.accounts.childrenCompaniesInUpdate,
	)
	/** company ID */
	const [account, setAccount] = useState<string | null>(null)
	const [childrenAccount, setChildrenAccount] = useState(childrenCompanies)
	const [childrenAccountOptions, setChildrenAccountOptions] = useState<NestableCompanyInfo[]>([])
	const getListCompaniesByUSer = useSelector<ElementState, ElementState['companiesByUser']>(
		(state) => state.companiesByUser,
	)

	useEffect(() => {
		const result = async () => {
			const accountId = account
			if (accountId !== undefined && accountId !== null) {
				const [error, data] = await getFullChildCompanies({ headers: { 'X-Company-Id': accountId } })
				if (!error) {
					let options: NestableCompanyInfo[] = []
					const localChildrenCompany = data?.data?.data?.companies?.companiesTree ?? []
					localChildrenCompany.forEach((element) => {
						options.push(element) // level 2
						if (element?.children?.length !== 0) {
							const childrenLevel3 = element.children
							childrenLevel3.forEach((elemLv3) => {
								options.push(elemLv3) // level 3
								if (elemLv3?.children?.length !== 0) {
									options = [...options, ...elemLv3.children] // level 4
								}
							})
						}
					})
					setChildrenAccountOptions(options)
				}
			}
		}
		result()
	}, [parentAccount, isFromEditAccount, accountData, account])

	useEffect(() => {
		dispatch(getListCompaniesByUser())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setChildrenAccount(childrenCompanies)
	}, [childrenCompanies])

	useEffect(() => {
		if (isEdit) {
			const selectedChildrenAccounts = childrenAccountOptions.filter((item) =>
				editUserChildrenCompanies.includes(+item.id),
			)
			setChildrenCompanies(selectedChildrenAccounts)
			setChildrenAccount(selectedChildrenAccounts)
		}
	}, [isEdit, childrenAccountOptions, editUserChildrenCompanies, setChildrenCompanies])

	useEffect(() => {
		if (isFromEditAccount) {
			if (Object.keys(accountData || {}).length !== 0) {
				setAccount(accountData!.id)
				setValue('account', accountData!.id)
			} else {
				setAccount(parentCompanyInUpdate.id)
				setValue('account', parentCompanyInUpdate.id)
			}
		} else if (!account && accountData?.parentCompany) {
			setAccount(accountData.parentCompany.id)
			setValue('account', accountData.parentCompany.id)
		}
	}, [isFromEditAccount, parentCompanyInUpdate, setValue, childrenCompaniesInUpdate, accountData, account])

	const handleSelectImage: ChangeEventHandler<HTMLInputElement> = (e) => {
		const { files } = e.target
		if (files && files.length) {
			setUserPhoto(files)
		}
	}

	return (
		<>
			<div className={classes.newUserIconTitle}>
				<div className={classes.newUserIconContainer}>
					<InfoOutlinedIcon className={classes.newUserIcon} />
				</div>
				<h3 className={classes.newUserSectionTitle}>{!isEditProfile ? 'Basic Information' : 'Personal Information'}</h3>
			</div>
			<div className={classes.innerContainer}>
				<div className={classes.inputs}>
					<div className={classes.inputsRow}>
						<div style={{ position: 'relative' }}>
							<input
								type="file"
								className={classes.dragAndDropUploader}
								onChange={handleSelectImage}
								accept="image/*"
							/>
							{!userPhoto.length ? (
								<Grid item>
									<Badge
										style={{ marginRight: '20px', cursor: 'pointer' }}
										badgeContent={<PhotoCameraIcon style={{ width: '12px' }} />}
										color="secondary"
									>
										<p className={classes.uploadPhotoTitle}>Upload Photo</p>
										<Avatar className={`${classes.avatar} ${classes.avatarBorder}`} />
									</Badge>
								</Grid>
							) : (
								<Grid item>
									<Badge
										style={{ marginRight: '20px', cursor: 'pointer' }}
										badgeContent={<PhotoCameraIcon style={{ width: '12px' }} />}
										color="secondary"
									>
										<p className={classes.uploadPhotoTitle}>Upload Photo</p>
										<Avatar
											className={`${classes.avatar} ${classes.avatarBorder}`}
											src={URL.createObjectURL(userPhoto[0])}
										/>
									</Badge>
								</Grid>
							)}
						</div>
						<TextField
							name="firstName"
							type="text"
							label="First Name *"
							className={classes.textField}
							inputRef={register({
								required: { value: true, message: 'This value is required' },
								maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
							})}
							variant="outlined"
							FormHelperTextProps={{ classes: { root: classes.helperText } }}
							error={!!errors.firstName}
							helperText={errors.firstName ? errors.firstName.message : null}
							InputLabelProps={{
								shrink: true,
							}}
						/>
						{!isFromEditAccount && !isEditProfile && (
							<TextField
								name="lastName"
								type="text"
								label="Last Name *"
								className={classes.textField}
								inputRef={register({
									required: { value: true, message: 'This value is required' },
									maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
								})}
								variant="outlined"
								FormHelperTextProps={{ classes: { root: classes.helperText } }}
								error={!!errors.lastName}
								helperText={errors.lastName ? errors.lastName.message : null}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
					</div>
					<div className={classes.inputsRow}>
						{(isFromEditAccount || isEditProfile) && (
							<TextField
								name="lastName"
								type="text"
								label="Last Name *"
								className={classes.textField}
								inputRef={register({
									required: { value: true, message: 'This value is required' },
									maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
								})}
								variant="outlined"
								FormHelperTextProps={{ classes: { root: classes.helperText } }}
								error={!!errors.lastName}
								helperText={errors.lastName ? errors.lastName.message : null}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
						<TextField
							name="phone_number"
							type="text"
							label={!isEditProfile ? 'Contact Phone' : 'Phone number'}
							variant="outlined"
							value={phoneNumber}
							className={classes.textField}
							onChange={(e) => setPhoneNumber(e.target.value)}
							inputRef={register()}
							InputProps={{
								inputComponent: PhoneNumberFormat,
							}}
							FormHelperTextProps={{ classes: { root: classes.helperText } }}
							error={Boolean(errorPhoneNumber && errorPhoneNumber.length > 0)}
							helperText={errorPhoneNumber && errorPhoneNumber.length > 0 ? errorPhoneNumber : null}
						/>
						{!isFromEditAccount && !isEditProfile && (
							<TextField
								name="email"
								type="text"
								label="Contact Email *"
								variant="outlined"
								disabled={isEdit}
								className={classes.textField}
								inputRef={register({
									required: { value: true, message: 'This value is required' },
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
										message: 'Please enter a valid email format',
									},
									maxLength: { value: 500, message: 'Maximum 500 characters allowed' },
								})}
								FormHelperTextProps={{ classes: { root: classes.helperText } }}
								error={!!errors.email}
								helperText={errors.email ? errors.email.message : null}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
					</div>
				</div>
				<div className={classes.innerContainer} style={{ padding: 0 }}>
					<Grid
						container
						direction={matches ? 'column' : 'row'}
						justify="space-between"
						style={{
							rowGap: '24px',
						}}
					>
						{(isFromEditAccount || isEditProfile) && (
							<TextField
								name="email"
								type="text"
								label={!isEditProfile ? 'Contact Email *' : 'Email address *'}
								variant="outlined"
								className={isFromEditAccount || isEditProfile ? classes.textFieldSmall : classes.textField}
								inputProps={{ readOnly: isEditProfile }}
								inputRef={register({
									required: { value: true, message: 'This value is required' },
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
										message: 'Please enter a valid email format',
									},
									maxLength: { value: 100, message: 'Maximum 100 characters allowed' },
								})}
								FormHelperTextProps={{ classes: { root: classes.helperText } }}
								error={!!errors.email}
								helperText={errors.email ? errors.email.message : null}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
						{!isEditProfile && (
							<>
								<FormControl
									variant="outlined"
									className={isFromEditAccount ? classes.formControlSmall : classes.formControl}
									error={!!errors.account}
								>
									<InputLabel className={classes.accountInput} htmlFor="company-dialog-select">
										Account *
									</InputLabel>
									<Controller
										render={({ onChange, value, name }) => (
											<Select
												name={name}
												value={value}
												disabled={Boolean(isFromEditAccount)}
												onChange={(e) => {
													onChange(e.target.value)
													setAccount(e.target.value as any)
													setCompanyAccount(e.target.value)
												}}
											>
												{getListCompaniesByUSer &&
													getListCompaniesByUSer.companies.map((company: any, index: number) => (
														<MenuItem key={`id-${index + 1}-available-company`} value={company.id}>
															{company.name}
														</MenuItem>
													))}
											</Select>
										)}
										name="account"
										control={control as any}
										defaultValue={account || ''}
										rules={{ required: true }}
									/>
								</FormControl>
								<Autocomplete
									multiple
									disableCloseOnSelect
									className={isFromEditAccount ? classes.autocompleteSmall : classes.autocomplete}
									options={childrenAccountOptions}
									getOptionLabel={(option) => option?.name}
									value={childrenCompanies}
									onChange={(event, changedValues) => {
										const newValue = changedValues as Array<NestableCompanyInfo | CompanyInfo>
										let newSelectedValues: Array<NestableCompanyInfo | CompanyInfo> = []
										newValue.forEach((item: NestableCompanyInfo | CompanyInfo) => {
											if (item.level === 2) {
												newSelectedValues = [...newSelectedValues, item] // level 2
											}
										})
										newValue.forEach((item) => {
											if (item.level === 3 && newSelectedValues.findIndex((ind) => ind.id === item.parent_id) !== -1) {
												newSelectedValues.push(item) // level 3
											}
										})
										newValue.forEach((item) => {
											if (item.level === 4 && newSelectedValues.findIndex((ind) => ind.id === item.parent_id) !== -1) {
												newSelectedValues.push(item) // level 4
											}
										})
										setChildrenCompanies(newSelectedValues)
										setChildrenAccount(newSelectedValues)
									}}
									filterOptions={(options, params) => filterCompaniesBySearchText(options, params.inputValue)}
									getOptionSelected={(option, value) => option.id === value.toString() || option.id === value.id}
									getOptionDisabled={(option) =>
										option.level !== 2 && childrenCompanies.findIndex((index) => index.id === option.parent_id) === -1
									}
									renderOption={(option, { selected }) => (
										<div
											style={{
												marginLeft: option.level !== 2 ? `${option.level - 2}em` : 0,
											}}
										>
											<Checkbox
												color="secondary"
												className={classes.checkBox}
												style={{ transform: 'scale(0.8)', padding: '0 5px 0 0' }}
												checked={selected}
											/>
											{option.name}
										</div>
									)}
									renderInput={(params) => (
										<TextField
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...params}
											name="childAccounts"
											label="Child Accounts"
											margin="normal"
											variant="outlined"
											value={childrenAccount}
											FormHelperTextProps={{ classes: { root: classes.helperText } }}
										/>
									)}
								/>
							</>
						)}
					</Grid>
				</div>
			</div>
		</>
	)
}

BasicInformation.propTypes = propTypes

BasicInformation.defaultProps = {
	isFromEditAccount: false,
	isEditProfile: false,
	accountData: {},
}

export default BasicInformation
