import React from 'react'
import PropTypes from 'prop-types'

import { TableCell, TableRow, Radio, useTheme } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import useStyles from '../styles'

const TableRowSavedItems = (props) => {
	const theme = useTheme()
	const classes = useStyles()
	const { labelId, isItemSelected, data, selected, setSelected } = props

	const handleClick = (event, item) => {
		setSelected({ ...item })
	}

	const displayNMFC = () => {
		if (!data.nmfc) {
			return '-'
		}
		if (!data.sub_nmfc) {
			return data.nmfc
		}
		return data.nmfc.concat('-', data.sub_nmfc)
	}

	return (
		<>
			<TableRow
				className={classes.tableRow}
				hover
				role="checkbox"
				aria-checked={selected.id === data.id}
				tabIndex={-1}
				key={data.id}
				selected={selected.id === data.id}
				onClick={(event) => handleClick(event, data)}
			>
				<TableCell padding="checkbox">
					<Radio
						inputProps={{ 'aria-labelledby': labelId }}
						checked={isItemSelected}
						style={{
							color: isItemSelected ? theme.palette.secondary.main : theme.palette.primary.ligh,
						}}
					/>
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.description}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.name ?? '-'}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.pieces_unit ?? '-'}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.pieces_value ?? '-'}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.class ?? '-'}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{displayNMFC()}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.weight_value ?? '-'}
				</TableCell>
				<TableCell align="left" className={classes.tableCell}>
					{data.dimensions_width && data.dimensions_height && data.dimensions_lenght
						? `${data.dimensions_width}" x ${data.dimensions_height}" x ${data.dimensions_lenght}"`
						: '-'}
				</TableCell>
				<TableCell align="center" className={classes.tableCell}>
					{data.is_haz_mat ? <CheckIcon fontSize="small" /> : <ClearIcon fontSize="small" />}
				</TableCell>
			</TableRow>
		</>
	)
}

TableRowSavedItems.propTypes = {
	labelId: PropTypes.string.isRequired,
	isItemSelected: PropTypes.bool.isRequired,
	data: PropTypes.objectOf(PropTypes.any).isRequired,
	selected: PropTypes.objectOf(PropTypes.any),
	setSelected: PropTypes.func.isRequired,
}

TableRowSavedItems.defaultProps = {
	selected: { id: null },
}

export default TableRowSavedItems
