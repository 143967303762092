import type { AxiosRequestConfig } from 'axios'
import axiosUser from '../../utils/apiClient'
import type { ApiResponse } from '../types/ApiResponse'
import type {
	BulkCreateCompanyRequest,
	BulkUpdateCompanyStatusRequest,
	CompaniesBelowResponse,
	CompaniesTreeResponse,
	CompanyRequest,
	CompanySettingRequest,
	EditAccountStepStatusRequest,
	EditCompanyStatusRequest,
	SupportRequest,
	UpdateCompanyRequest,
} from './Accounts.types'

export const reqUserCompanies = async (params: string, config: AxiosRequestConfig) => {
	const [error, data] = await axiosUser
		.get(`/company${params}`, config)
		.then((setting) => [null, setting.data.data])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqUserChildrenCompanies = async (params: string, config: AxiosRequestConfig) => {
	const [error, data] = await axiosUser
		.get(`/company/children/${params}`, config)
		.then((setting) => [null, setting.data.data])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqCreateAccounts = async (body: CompanyRequest, config: AxiosRequestConfig) => {
	const [error, data] = await axiosUser
		.post('/company/', body, config)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.message])
	return [error, data]
}

export const reqCreateAccountSettings = async (body: CompanySettingRequest, config: AxiosRequestConfig) => {
	const [error, data] = await axiosUser
		.post('/company/component/settings', body, config)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.message])
	return [error, data]
}

export const reqGetAccountSettings = async (config: AxiosRequestConfig) => {
	const [error, data] = await axiosUser
		.get('/company/component/settings', config)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.message])
	return [error, data]
}

export const reqGetAccountInformation = async (config: AxiosRequestConfig, id: string) => {
	// console.log('config', config);
	// console.log('id', id);
	const [error, data] = await axiosUser
		.get(`/company/parent-children/${id}`, config)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.message])
	// console.log('data from user lookup', data);
	return [error, data]
}

export const reqEditAccount = async (body: UpdateCompanyRequest, id: string) => {
	const [error, data] = await axiosUser
		.put(`/company/${id}`, body)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.message])
	return [error, data]
}

export const reqEditAccountStatus = async (params: EditCompanyStatusRequest, config: AxiosRequestConfig) => {
	const [error, data] = await axiosUser
		.patch('/company', params, config)
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqEditAccountStepStatus = async (params: string, body: EditAccountStepStatusRequest) => {
	const [error, data] = await axiosUser
		.patch(`/company/complete-step${params}`, body)
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export const reqBulkAccountsCreation = async (body: BulkCreateCompanyRequest, config: AxiosRequestConfig) => {
	const [error, data] = await axiosUser
		.post('/company/bulk', body, config)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.err])
	return [error, data]
}

export const reqBulkAccountsStatusUpdate = async (body: BulkUpdateCompanyStatusRequest, config: AxiosRequestConfig) => {
	const [error, data] = await axiosUser
		.patch('/company/bulk/status', body, config)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.message])
	return [error, data]
}

export const postTicketToSupport = async (body: SupportRequest, config: AxiosRequestConfig) => {
	const [error, data] = await axiosUser
		.post('/support', body, config)
		.then((response) => [null, response.data.data])
		.catch((err) => [err, err.response.data.err])
	return [error, data]
}

export const getFullChildCompanies = async (config: {
	headers: { 'X-Company-Id': string | number }
}): Promise<ApiResponse<CompaniesBelowResponse>> => {
	const [error, data] = await axiosUser
		.get<CompaniesBelowResponse>('/company/companies/below', config)
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export const getCompaniesTree = async (): Promise<ApiResponse<CompaniesTreeResponse>> => {
	const [error, data] = await axiosUser
		.get('/company/companies/tree')
		.then((response) => [null, response])
		.catch((err) => [err, err])
	return [error, data]
}

export default {
	reqUserCompanies,
	reqCreateAccounts,
	reqCreateAccountSettings,
	reqGetAccountSettings,
	reqEditAccount,
	reqEditAccountStatus,
	reqBulkAccountsCreation,
	reqBulkAccountsStatusUpdate,
	getFullChildCompanies,
	getCompaniesTree,
}
