import axiosUser, { axiosQuotes, axiosAudit, axiosInvoices, axiosReports, axiosUserCompany } from './apiClient'

/* eslint no-param-reassign: ["error", { "props": false }] */

export function setQuoteConfig({ token, companyId }) {
	axiosQuotes.interceptors.request.use(
		(config) => {
			if (!config.headers.Authorization && token) config.headers.Authorization = `Bearer ${token}`
			if (!config.headers['X-Company-id'] && companyId) config.headers['X-Company-id'] = Number(companyId)
			return config
		},
		(error) => Promise.reject(error),
	)
	axiosQuotes.interceptors.response.use(
		(config) => {
			if (config) {
				if (!config.headers.Authorization && token) {
					config.headers.Authorization = `Bearer ${token}`
				}
				if (!config.headers['X-Company-id'] && companyId) {
					config.headers['X-Company-id'] = Number(companyId)
				}
			}
			return config
		},
		(error) => {
			const response = error.request?.response ? JSON.parse(error.request.response) : error
			return Promise.reject(response)
		},
	)
}

export async function setUserConfig(token) {
	const use = await axiosUser.interceptors.request.use(
		(config) => {
			if (!config.headers.Authorization && token) config.headers.Authorization = `Bearer ${token}`
			return config
		},
		(error) => Promise.reject(error),
	)
	return use
}

export function setUserCompanyConfig({ token, companyId }) {
	axiosUserCompany.interceptors.request.use(
		(config) => {
			if (!config.headers.Authorization && token) config.headers.Authorization = `Bearer ${token}`
			if (!config.headers['X-Company-id'] && companyId) config.headers['X-Company-id'] = Number(companyId)
			return config
		},
		(error) => Promise.reject(error),
	)
}

export function setAuditConfig({ token, companyId }) {
	axiosAudit.interceptors.request.use(
		(config) => {
			if (!config.headers.Authorization && token) config.headers.Authorization = `Bearer ${token}`
			if (!config.headers['X-Company-id'] && companyId) config.headers['X-Company-id'] = Number(companyId)
			return config
		},
		(error) => Promise.reject(error),
	)
}

export function setInvoiceConfig({ token, companyId }) {
	axiosInvoices.interceptors.request.use(
		(config) => {
			if (!config.headers.Authorization && token) config.headers.Authorization = `Bearer ${token}`
			if (!config.headers['X-Company-id'] && companyId) config.headers['X-Company-id'] = Number(companyId)
			return config
		},
		(error) => Promise.reject(error),
	)
}

export function setReportsConfig({ token, companyId }) {
	axiosReports.interceptors.request.use(
		(config) => {
			if (!config.headers.Authorization && token) config.headers.Authorization = `Bearer ${token}`
			if (!config.headers['X-Company-id'] && companyId) config.headers['X-Company-id'] = Number(companyId)
			return config
		},
		(error) => Promise.reject(error),
	)
}
