/* eslint no-param-reassign: ["error", { "props": false }] */
import { createSlice } from '@reduxjs/toolkit'
import { getCompaniesTree } from '../requests/Accounts.request'
import type { CompanyInfo, NestableCompanyInfo } from '../requests/Accounts.types'

const companysByUserSlice = createSlice({
	name: 'companysByUser',
	initialState: {
		companies: [] as Array<NestableCompanyInfo | CompanyInfo>,
	},
	reducers: {
		loadCompanysByUser: (state, action: { payload: Array<NestableCompanyInfo | CompanyInfo> }) => {
			state.companies = action.payload
		},
	},
})

const { actions, reducer } = companysByUserSlice

export const { loadCompanysByUser } = actions

export const getListCompaniesByUser = () => async (dispatch: any) => {
	const [error, data] = await getCompaniesTree()
	if (!error) {
		let options: Array<NestableCompanyInfo | CompanyInfo> = []
		const localChildrenCompany = data?.data?.companiesTree ?? []
		if (data?.data?.principalCompany) {
			const principalCompany = data?.data?.principalCompany[0]
			options.push(principalCompany) // level 1
		}
		localChildrenCompany.forEach((element) => {
			options.push(element) // level 2
			if (element?.children?.length !== 0) {
				const childrenLevel3 = element.children
				childrenLevel3.forEach((elemLv3) => {
					options.push(elemLv3) // level 3
					if (elemLv3?.children?.length !== 0) {
						options = [...options, ...elemLv3.children] // level 4
					}
				})
			}
		})
		dispatch(loadCompanysByUser(options))
	}
}

export default reducer
