import type { NestableCompanyInfo } from '../../../../../redux/requests/Accounts.types'

/*
	searches company and its nested children to see if any of them match the search text
	possible enhancements - search for siblings?
*/
function companyMatchesSearchText(company: Partial<NestableCompanyInfo>, searchText: string): boolean {
	const directNameMatch = company.name?.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
	if (directNameMatch) {
		return true
	}
	if (company.children) {
		const childMatches = company.children.some((child) => companyMatchesSearchText(child, searchText))
		if (childMatches) {
			return true
		}
	}
	return false
}

export function filterCompaniesBySearchText<T extends Partial<NestableCompanyInfo>>(
	companies: Array<T>,
	searchText: string,
): Array<T> {
	return companies.filter((company) => companyMatchesSearchText(company, searchText))
}

export default { filterCompaniesBySearchText }
