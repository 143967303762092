const savedQuotesCells = [
	{ id: 'quote', label: '# QUOTE' },
	{ id: 'origin', label: 'ORIGIN' },
	{ id: 'destination', label: 'DESTINATION' },
	{ id: 'company', label: 'COMPANY' },
	{ id: 'carrier', label: 'CARRIER' },
	{ id: 'customerRate', label: 'CUSTOMER RATE' },
	{ id: 'quoteDate', label: 'QUOTE DATE' },
	{ id: 'status', label: 'STATUS' },
	{ id: 'actions', label: 'ACTIONS' },
]

const savedShipmentsCells = [
	{ id: 'company', label: 'COMPANY' },
	{ id: 'primaryRef', label: 'PRIMARY REF' },
	{ id: 'targetPickUpDelivery', label: 'TARGET PICKUP/DELIVERY' },
	{ id: 'carrier', label: 'CARRIER' },
	{ id: 'originDestination', label: 'ORIGIN/DESTINATION' },
	{ id: 'status', label: 'STATUS' },
	{ id: 'actions', label: 'ACTIONS' },
]

const cubicCalculatorCells = [
	{ id: 'number', label: '#' },
	{ id: 'cubicFeet', label: 'Cubic feet' },
	{ id: 'cubicMeters', label: 'Cubic meters' },
	{ id: 'densityLb', label: 'Density lb/cubic ft' },
	{ id: 'densityKg', label: 'Density kg/cubic m' },
	{ id: 'linearFootage', label: 'Linear footage' },
]

const shipmentCells = [
	{ id: 'number', label: '# Shipment' },
	{ id: 'create_date', label: 'Create Date' },
	{ id: 'owner', label: 'owner' },
	{ id: 'Primary_Ref', label: 'Primary Ref' },
	{ id: 'Target_Pickup_Delivery', label: 'Target Pickup/ Delivery' },
	{ id: 'Carrier', label: 'Carrier' },
	{ id: 'Origin_Destination', label: 'Origin/Destination' },
	{ id: 'status', label: 'status' },
	{ id: 'actions', label: 'actions' },
]

const locationCells = [
	{ id: 'id', label: 'LOCATION ID' },
	{ id: 'name', label: 'COMPANY' },
	{ id: 'address', label: 'ADDRESS 1' },
	{ id: 'address2', label: 'ADDRESS 2' },
	{ id: 'city', label: 'CITY' },
	{ id: 'state', label: 'STATE' },
	{ id: 'zip_code', label: 'ZIP CODE' },
	{ id: 'country', label: 'COUNTRY' },
	{ id: 'contact', label: 'CONTACT NAME' },
	{ id: 'phone', label: 'CONTACT PHONE' },
	{ id: 'accesorials', label: 'ACCESSORIALS' },
]

const templateItemCells = [
	{ id: 'description', label: 'DESCRIPTION' },
	{ id: 'name', label: 'ITEM ID' },
	{ id: 'packageType', label: 'PACKAGE TYPE' },
	{ id: 'quantity', label: 'QUANTITY' },
	{ id: 'class', label: 'FREIGHTCLASS' },
	{ id: 'nmfc', label: 'NMFC CODE' },
	{ id: 'weight', label: 'WEIGHT(LB)' },
	{ id: 'dimensions', label: 'DIMENSIONS(IN)' },
	{ id: 'isHazMaterial', label: 'HAZ MATERIAL' },
]

const productItemCells = [
	{ id: 'default', label: '' },
	{ id: 'description', label: 'DESCRIPTION' },
	{ id: 'isHazMaterial', label: '' },
	{ id: 'quantity', label: 'QUANTITY' },
	{ id: 'packageType', label: 'PACKAGE TYPE' },
	{ id: 'weight', label: 'WEIGHT(LB)' },
	{ id: 'class', label: 'FREIGHTCLASS' },
	{ id: 'nmfc', label: 'NMFC CODE' },
	{ id: 'details', label: 'DETAILS' },
	{ id: 'itemId', label: 'ITEM ID' },
	{ id: 'actions', label: 'ACTIONS' },
]

const locationTableCells = [
	{ id: 'TYPE', label: '', sortable: false },
	{ id: 'NAME', label: 'COMPANY NAME', sortable: true },
	{ id: 'ADDRESS', label: 'ADDRESS', sortable: true },
	{ id: 'CONTACT', label: 'CONTACT INFORMATION', sortable: true },
	{ id: 'ACCESSORIALS', label: 'ACCESSORIALS', sortable: false },
	{ id: 'LOCATION_ID', label: 'LOCATION ID', sortable: true },
	{ id: 'actions', label: 'ACTIONS', sortable: false },
]

const invoiceTableCells = [
	{ id: 'number', label: 'INVOICE NUMBER', sortable: true },
	{ id: 'date', label: 'INVOICE DATE', sortable: true },
	{ id: 'due_date', label: 'DUE DATE', sortable: true },
	{ id: 'total', label: 'TOTAL AMOUNT', sortable: true },
	{ id: 'balance', label: 'BALANCE DUE AMOUNT', sortable: true },
	{ id: 'status', label: 'STATUS', sortable: true },
	{ id: 'days_past', label: 'DAYS PAST DUE', sortable: true },
]

const invoiceDetailTableCells = [
	{ id: 'ref', label: 'PRIMARY REF', sortable: false },
	{ id: 'ship_date', label: 'SHIP DATE', sortable: false },
	{ id: 'carrier', label: 'CARRIER', sortable: false },
	{ id: 'origin', label: 'ORIGIN', sortable: false },
	{ id: 'destination', label: 'DESTINATION', sortable: false },
	{ id: 'weight', label: 'WEIGHT/ #PIECES', sortable: false },
	{ id: 'total_amount', label: 'TOTAL AMOUNT', sortable: false },
	{ id: 'actions', label: '', sortable: false },
]

const carrierTableCells = [
	{ id: 'carrier', label: 'CARRIER', sortable: false },
	{ id: 'scac', label: 'SCAC', sortable: false },
	{ id: 'carrier_group', label: 'CARRIER GROUP', sortable: false },
	{ id: 'contract_type', label: 'CONTRACT TYPE', sortable: false },
	{ id: 'service_type', label: 'SERVICE TYPE', sortable: false },
	{ id: 'margin', label: 'MARGIN', sortable: false },
	{ id: 'minimum', label: 'MINIMUM', sortable: false },
	{ id: 'accessorials', label: 'ACCESSORIALS', sortable: false },
	{ id: 'fuel', label: 'FUEL', sortable: false },
]

const carrierListTableCells = [
	{ id: 'carrier', label: 'CARRIER', sortable: false },
	{ id: 'scac', label: 'SCAC', sortable: false },
	{ id: 'type', label: 'TYPE', sortable: false },
	{ id: 'carrier_group', label: 'CARRIER GROUP', sortable: false },
	{ id: 'service_type', label: 'SERVICE TYPE', sortable: false },
	{ id: 'status', label: 'STATUS', sortable: false },
]

const carrierContractListTableCells = [
	{ id: 'carrier', label: 'CARRIER', sortable: false },
	{ id: 'scac', label: 'SCAC', sortable: false },
	{ id: 'service_type', label: 'SERVICE TYPE', sortable: false },
]

const accountsTableCells = [
	{ id: 'name', label: 'COMPANY', sortable: true },
	{ id: 'contact', label: 'CONTACT NAME', sortable: true },
	{ id: 'audit_creation_date', label: 'CREATED DATE', sortable: true },
	{ id: 'audit_update_date', label: 'LAST UPDATED', sortable: true },
	{ id: 'status', label: 'STATUS', sortable: false },
	{ id: 'actions', label: 'ACTIONS', sortable: false },
]

const usersTableCells = [
	{ id: 'name', label: 'NAME', sortable: false },
	{ id: 'role', label: 'ROLE', sortable: false },
	{ id: 'main_account', label: 'MAIN ACCOUNT', sortable: false },
	{ id: 'phone_number', label: 'PHONE NUMBER', sortable: false },
	{ id: 'email', label: 'EMAIL', sortable: false },
	{ id: 'status', label: 'STATUS', sortable: false },
]

const usersNewAccountTableCells = [
	{ id: 'first_name', label: 'FIRST NAME', sortable: false },
	{ id: 'last_name', label: 'LAST NAME', sortable: false },
	{ id: 'user_id', label: 'USER ID', sortable: false },
	{ id: 'user_email', label: 'USER EMAIL', sortable: false },
	{ id: 'profile', label: 'PROFILE', sortable: false },
]

export {
	savedQuotesCells,
	savedShipmentsCells,
	cubicCalculatorCells,
	templateItemCells,
	productItemCells,
	locationCells,
	shipmentCells,
	locationTableCells,
	invoiceTableCells,
	invoiceDetailTableCells,
	carrierTableCells,
	accountsTableCells,
	usersTableCells,
	usersNewAccountTableCells,
	carrierListTableCells,
	carrierContractListTableCells,
}
