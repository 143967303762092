import { makeStyles } from '@material-ui/core'
import type { FreightProsTheme } from '../../../../../themes/theme.types'

// TODO Theme should be a type imported from the definition in the file src/themes/freightpros.js
// that way so our color pallete overrides are available.
type ClassNameKeys =
	| 'newUserIconTitle'
	| 'newUserIconContainer'
	| 'newUserIcon'
	| 'newUserSectionTitle'
	| 'innerContainer'
	| 'inputs'
	| 'inputsRow'
	| 'dragAndDropUploader'
	| 'uploadPhotoTitle'
	| 'avatar'
	| 'avatarBorder'
	| 'textField'
	| 'textFieldSmall'
	| 'textFieldPassword'
	| 'helperText'
	| 'formControl'
	| 'formControlSmall'
	| 'accountInput'
	| 'autocomplete'
	| 'autocompleteSmall'
	| 'checkBox'

const useStyles = makeStyles<FreightProsTheme, {}, ClassNameKeys>((theme) => ({
	checkBox: {}, // was being referenced so added empty style to avoid type error
	newUserIconTitle: {
		display: 'flex',
		columnGap: '12px',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			alignItems: 'flex-start',
		},
	},
	newUserIconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '28px',
		height: '28px',
		borderRadius: '20px',
		color: theme.palette.neutral.black,
	},
	newUserIcon: {
		width: '20px',
		height: '20px',
	},
	newUserSectionTitle: {
		fontSize: '18px',
		fontWeight: theme.typography.fontWeightRegular as any,
		color: theme.palette.primary.main,
		margin: 0,
		lineHeight: '22px',
		[theme.breakpoints.down('md')]: {
			fontSize: '15px',
		},
	},
	innerContainer: {
		display: 'grid',
		gridAutoFlow: 'row',
		rowGap: '30px',
		paddingLeft: '16px',
		[theme.breakpoints.down('md')]: {
			paddingLeft: '0',
		},
	},
	inputs: {
		display: 'grid',
		gridAutoFlow: 'column',
		columnGap: '24px',
		gridTemplateColumns: 'repeat(2, 1fr)',
		[theme.breakpoints.down('md')]: {
			gridAutoFlow: 'row',
			gridTemplateColumns: 'none',
			rowGap: '24px',
		},
		'@media (min-width: 1700px)': {
			columnGap: '34px',
		},
	},
	inputsRow: {
		display: 'grid',
		gridAutoFlow: 'column',
		columnGap: '24px',
		[theme.breakpoints.down('md')]: {
			gridAutoFlow: 'row',
			rowGap: '24px',
		},
		'@media (min-width: 1700px)': {
			columnGap: '34px',
		},
	},
	dragAndDropUploader: {
		width: '100%',
		height: '100%',
		opacity: 0,
		position: 'absolute',
		zIndex: 1,
		cursor: 'pointer',
		[theme.breakpoints.down('lg')]: {
			width: '100%',
		},
		[theme.breakpoints.down('md')]: {
			width: '38%',
		},
	},
	uploadPhotoTitle: {
		fontSize: '12px',
		fontWeight: '400' as any,
		marginRight: '8px',
		cursor: 'pointer',
	},
	avatar: {
		color: theme.palette.secondary.main,
		fontWeight: 700,
		backgroundColor: theme.palette.neutral.white,
		height: '50px',
		width: '50px',
	},
	avatarBorder: {
		border: '1px solid',
		borderColor: theme.palette.secondary.main,
	},
	textField: {
		fontSize: '14px',
		backgroundColor: 'white',
		'& .MuiFormLabel-root': {
			fontSize: '14px',
		},
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
			margin: 80,
		},
		'& .MuiInputBase-root': {
			height: '50px',
		},
	},
	textFieldSmall: {
		width: '49%',
		fontSize: '14px',
		backgroundColor: 'white',
		'& .MuiFormLabel-root': {
			fontSize: '14px',
		},
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
			margin: 80,
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	textFieldPassword: {
		width: '49%',
		fontSize: '14px',
		backgroundColor: 'white',
		'& .MuiFormLabel-root': {
			fontSize: '14px',
		},
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
			margin: 80,
		},
		'& .MuiInputBase-root': {
			height: '50px',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	helperText: {
		fontSize: '12px',
		fontWeight: '400' as any,
		color: '#A1A1A1',
	},
	formControl: {
		width: '49%',
		'& .MuiInputBase-root': {
			minHeight: '50px',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	formControlSmall: {
		width: '23.5%',
		'& .MuiInputBase-root': {
			minHeight: '50px',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	accountInput: {
		'&.MuiInputLabel-outlined': {
			backgroundColor: '#ffffff',
		},
	},
	autocomplete: {
		width: '49%',
		'& .MuiInputBase-root': {
			minHeight: '50px',
		},
		'& .MuiFormControl-root': {
			margin: 0,
		},
		'& .MuiChip-deleteIcon': {
			color: theme.palette.secondary.main,
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	autocompleteSmall: {
		width: '23.5%',
		'& .MuiInputBase-root': {
			minHeight: '50px',
		},
		'& .MuiFormControl-root': {
			margin: 0,
		},
		'& .MuiChip-deleteIcon': {
			color: theme.palette.secondary.main,
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
}))

export default useStyles
